// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import 'viewerjs/dist/viewer.css';
@import 'colors.scss';
@import 'buttons.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary-pallete: (
  100: var(--primary-color-lighter),
  300: var(--primary-color-light),
  500: var(--primary-color),
  700: var(--primary-color-dark),
  900: var(--primary-color-darker),
  contrast: (
    100: white,
    300: white,
    500: white,
    700: white,
    900: white
  )
);
$datubim-primary: mat.m2-define-palette($primary-pallete, 500, 100, 900);

$accent-pallete: (
  100: var(--accent-color-lighter),
  300: var(--accent-color-light),
  500: var(--accent-color),
  700: var(--accent-color-dark),
  900: var(--accent-color-darker),
  contrast: (
    100: white,
    300: white,
    500: white,
    700: white,
    900: white
  )
);
$datubim-accent: mat.m2-define-palette($accent-pallete, 500, 100, 900);

// The warn palette is optional (defaults to red).
$datubim-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$datubim-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $datubim-primary,
      accent: $datubim-accent,
      warn: $datubim-warn
    ),
    typography: mat.m2-define-typography-config(
        $font-family: 'Roboto, Helvetica Neue, sans-serif'
      ),
    density: 0
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($datubim-theme);

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

@import '@ng-select/ng-select/themes/default.theme.css';
@import './styles/common.scss';

html,
body,
#cesiumContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.cesium-widget-cesiumNavigationContainer {
  position: absolute;
  right: 10px;
}

.cesium-widget-errorPanel {
  .cesium-widget-errorPanel-content {
    .cesium-widget-errorPanel-scroll {
      max-height: unset !important;
    }

    .cesium-widget-errorPanel-buttonPanel {
      display: none;
    }
  }
}

.cesium-viewer-toolbar {
  z-index: 1000;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: color-mix(in srgb, var(--background-dark) 20%, transparent);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--background-light);
  border: 2px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  background-color: color-mix(in srgb, var(--background-dark) 20%, transparent);
}

.mat-mdc-tooltip {
  font-size: 12px;
  line-height: normal;

  &.tooltip-break-spaces {
    white-space: break-spaces;
  }

  .mat-mdc-tooltip-surface {
    text-align: start;
  }

  &.large-tooltip {
    white-space: break-spaces;

    .mat-mdc-tooltip-surface {
      max-width: 300px;
    }
  }
}

.mdc-button {
  -webkit-font-smoothing: antialiased;
  --mdc-typography-button-letter-spacing: 0px;

  --mat-text-button-with-icon-horizontal-padding: 12px;
}

.mat-mdc-button,
.mat-mdc-outlined-button {
  &:disabled {
    pointer-events: all !important;
  }
}

.mat-mdc-button,
.mat-mdc-outlined-button {
  &:disabled {
    &:active,
    &:hover {
      .mat-mdc-button-persistent-ripple::before {
        --mat-mdc-button-persistent-ripple-color: none;
      }
    }
  }
}

.mat-mdc-menu-item:disabled {
  pointer-events: all !important;
}

.mat-mdc-slide-toggle {
  --mdc-theme-surface: var(--primary-color);

  .mdc-switch {
    --mdc-switch-selected-handle-color: var(--primary-color);
    --mdc-switch-selected-track-color: var(--primary-color-lighter);
    --mdc-switch-unselected-track-color: var(--divider);
    --mdc-switch-selected-focus-state-layer-color: var(--primary-color);
    --mdc-switch-selected-hover-state-layer-color: var(--primary-color);
    --mdc-switch-selected-pressed-state-layer-color: var(--primary-color);

    &.mdc-switch--unselected.mdc-switch--disabled {
      --mdc-switch-handle-surface-color: var(--light-grey);
    }

    // Hide icon inside switch handle
    .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons {
      display: none;
    }
  }
}

.ng-select {
  border: 0px;
  min-height: 0px;
  border-radius: 3px;
}

.ng-select.ng-touched.ng-invalid .ng-select-container {
  border-color: var(--error) !important;
}

.ng-select .ng-select-container {
  border: solid 1px var(--grey) !important;
  background-color: var(--light-grey) !important;
  height: 36px !important;
  font-size: 14px !important;
  min-height: 0;
  border-radius: 3px !important;

  .ng-value-container .ng-input {
    height: calc(100% - 5px);

    input {
      height: calc(100% - 5px);
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  div .ng-option {
    padding: 6px 7px;
    font-size: 13px;
    &.ng-option-marked {
      background-color: var(--light-grey);
      color: var(--primary-color);
    }
    &.ng-option-selected,
    &.ng-option-marked.ng-option-selected {
      color: var(--text-dark);
      background-color: var(--primary-color-light);
    }
    input:checked {
      accent-color: var(--accent-color);
    }
  }

  .ng-optgroup {
    font-size: 13px;
    text-transform: uppercase;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

label {
  cursor: inherit;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}

.shape-editor-tooltip {
  opacity: 0.9 !important;
  min-width: 200px !important;

  .mat-mdc-snackbar-surface {
    pointer-events: none !important;
  }
}

.mat-mdc-snack-bar-container.multiline-snack-bar {
  white-space: pre;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 100% !important;
  width: 100% !important;
}

.mat-mdc-checkbox .mat-mdc-checkbox-touch-target {
  height: 100% !important;
  width: 100% !important;
}

a,
button,
div {
  -webkit-tap-highlight-color: transparent;
}

.mat-mdc-table {
  min-width: unset !important;
}

.mat-mdc-form-field-error {
  color: var(--error);
}

mat-radio-button .mdc-radio--disabled + label {
  cursor: default !important;
}

mat-radio-group:not(:disabled) mat-radio-button:not(:disabled) .mdc-form-field > label {
  cursor: pointer;
}

.cesium-credits-container {
  background-color: color-mix(in srgb, var(--white) 50%, transparent);
  box-shadow: 0 10px 35px 0 rgba(51, 51, 89, 0.15);
  padding: 0 5px;

  a {
    font-size: 12px;
    color: color-mix(in srgb, var(--text-dark) 75%, transparent);
    text-decoration: none;

    &.mapbox-improve-map {
      font-weight: 700;
      margin-left: 2px;
    }

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.mat-mdc-checkbox .mat-internal-form-field {
  --mat-checkbox-label-text-size: 14px;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
  display: none;
}

.mat-mdc-checkbox
  .mdc-checkbox__native-control:focus:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  border-color: var(--mdc-checkbox-unselected-icon-color) !important;
}

.mat-mdc-autocomplete-panel mat-option {
  --mat-option-label-text-size: 16px;
}

.highlight-filter-string {
  color: var(--text);
}
